/* ============ COLORS ============ */
/*LM modified it for 161933000040901 #06a7e0;*/
/*LM modified it for 161933000040901 #00c4b3;*/
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ MARGINS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ BORDERS ============ */
/* ============ SIDEBARS ============ */
html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container {
  max-width: none;
}
html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .product-view span.fa, html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .product-view i.fa, html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .xm-grid-product span.fa, html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .xm-grid-product i.fa {
  color: #fff;
}
html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .product-view .product-shop.module-product-details .short-description, html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .xm-grid-product .product-shop.module-product-details .short-description {
  padding-top: 10px;
}
html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .product-view .product-shop.module-product-details .short-description *, html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .xm-grid-product .product-shop.module-product-details .short-description * {
  font-size: 14px;
}
html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .product-view .product-shop.module-product-details .product-type-data .price-box, html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .xm-grid-product .product-shop.module-product-details .product-type-data .price-box {
  text-align: left;
}
html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .product-view .product-shop.module-product-details .action-box ul li, html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .xm-grid-product .product-shop.module-product-details .action-box ul li {
  list-style: none;
}
html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .product-view .product-shop.module-product-details .add-cart-wrapper button.button.btn-cart.add-cart-button span span, html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .xm-grid-product .product-shop.module-product-details .add-cart-wrapper button.button.btn-cart.add-cart-button span span {
  padding: 0;
}
html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .product-view #product-tabs ul.tabs li a, html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .xm-grid-product #product-tabs ul.tabs li a {
  font-weight: normal;
  border: 1px solid #3662AE;
  border-width: 1px 1px 0 1px;
}
html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .product-view #product-tabs ul.tabs li a.current, html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .product-view #product-tabs ul.tabs li a:hover, html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .xm-grid-product #product-tabs ul.tabs li a.current, html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .xm-grid-product #product-tabs ul.tabs li a:hover {
  color: #fff;
  border-color: #3662AE;
}
html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .product-view .box-additional.box-tabs .gen-tabs #itemslider-related.owl-carousel .owl-item img, html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .xm-grid-product .box-additional.box-tabs .gen-tabs #itemslider-related.owl-carousel .owl-item img {
  width: auto;
}