@import './variables';

body {
    #root-wrapper {
        form#usre_rego {
            .fieldset {
                & > .form-list {
                    & > li:not(.single_field) {
                        clear: left;
                        
                        .registerFormFieldContainer {
                            width: 100%;
                            max-width: 48%;
                            margin-left: 1%;
                            margin-right: 1%;
                            float: left;
                        }
                    }
                    & > li.single_field {
                        width: 100%;
                        max-width: 48%;
                        margin-left: 1%;
                        margin-right: 1%;
                        float: left;

                        &.captcha-item, &.control {
                            clear: left;
                        }

                        .registerFormFieldContainer {
                            width: 100%;
                        }
                    }
                    & > div.registerFormFieldContainer {
                        width: 98%;
                        padding: 0 1%;

                        label {
                            &[for="customer-type"] + br + ul {
                                clear: left;
                            }
                        }
                    }
                }
            }
        }
    }
}