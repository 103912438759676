@import './variables';

.page-type-cart {
    body {
        #root-wrapper {
            .inline-search-results-quickorder {
                .highlight {
                    background-color: $blue;
                }
            }
        }
    }
}