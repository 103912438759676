@font-face {
	font-family: 'Flama';
	src: url(../fonts/FlamaSemicondensed-Basic.eot);
	src: url(../fonts/FlamaSemicondensed-Basic.eot) format('embedded-opentype'), 
	url(../fonts/FlamaSemicondensed-Basic.woff2) format('woff2'),
	url(../fonts/FlamaSemicondensed-Basic.woff) format('woff'),
	url(../fonts/FlamaSemicondensed-Basic.otf) format('opentype'),
	url(../fonts/FlamaSemicondensed-Basic.ttf) format('truetype');
	font-weight: normal;
}
@font-face {
	font-family: 'Flama';
	src: url(../fonts/FlamaSemicondensed-Semibold.eot);
	src: url(../fonts/FlamaSemicondensed-Semibold.eot) format('embedded-opentype'), 
	url(../fonts/FlamaSemicondensed-Semibold.woff2) format('woff2'),
	url(../fonts/FlamaSemicondensed-Semibold.woff) format('woff'),
	url(../fonts/FlamaSemicondensed-Semibold.otf) format('opentype'),
	url(../fonts/FlamaSemicondensed-Semibold.ttf) format('truetype');
	font-weight: bold;
}

/* ================================================ */
/* Init */
/* ================================================ */
body {
    font-family: 'Helvetica', sans-serif; /*LM for 161933000040901*/
    font-weight: 400;
    color: #000;
    font-size: 15px;
}

.account-view .main-container, .account-view .main-container>.main, body.account-view, html .wrapper {
    background-color: white !important;
}
.mm-panels {
    background: none !important;
}
.sidebar.in-sidebar {
background: none;
}
a {
    -moz-transition: all .8s ease;
    -webkit-transition: all .8s ease;
    -o-transition: all .8s ease;
    transition: all .8s ease;
    color: #000;
}

a > i {
    font-style: normal;
}
.checkout-onepage-index .main.container {
    max-width: 1440px
    }

.wrapper {
    border-top: none;
}

.xm-grid-header > .container {
    width: 100%;
    margin: 0;
    padding: 0;
    max-width: none;
}
.xm-grid-header > .container .nested-container {
    width: 100%;
    margin: 0;
}
.xm-grid-header .grid12-4 {
    margin: 0 1%;
}
.main {
    padding: 30px 0 0;
}

.main.container {
    position: relative;
}


button.button {
    -webkit-border-fit: lines;
    width: auto;
    border: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    cursor: pointer;
}

button.button span {
    background-color: #3662AE; /*LM for 161933000040901*/
    color: #fff;
    float: left;
    display: block;
    padding: 0;
    font-size: 15px;
    text-align: center;
    white-space: nowrap;
    transition: color .3s ease-in-out, background-color .3s ease-in-out;
    -moz-transition: color .3s ease-in-out, background-color .3s ease-in-out;
    -webkit-transition: color .3s ease-in-out, background-color .3s ease-in-out;
    -o-transition: color .3s ease-in-out, background-color .3s ease-in-out;
}

.product-view .btn-cart:hover span,
button.btn-checkout:hover span,
button.button:hover span {
    background-color: #3662AE; /*LM for 161933000040901*/
    color: #fff;
}

button.button span span {
    font-family: 'Helvetica', sans-serif; /*LM for 161933000040901*/
	font-weight: bold;
}

.buttons-set button.button {
    margin-left: 0;
    margin-right: 5px;
    margin-bottom: 5px;
}

.button.btn-inline span, .button.btn-checkout span {
    background-color: #3662AE; /*LM for 161933000040901*/
    color: #fff;
}

.regular-container {
    display: block !important;
    float: none;
    margin:auto;
}

@media only screen and (min-width: 960px) {
    .show-below-960 {
        display: none !important;
    }
    .xm-grid-header .grid12-4 {
        width: 31.33% !important;
    }
}
@media only screen and (max-width: 959px) {
    .hide-below-960 {
        display: none !important;
    }
    .xm-grid-header .grid12-4 {
        width: 48% !important;
    }
}
/* ================================================ */
/* Font and Colours */
/* ================================================ */
.main-font, h1, h2, h3, h4, h5, h6, .section-title, .products-list .product-name, .products-grid .product-name, h3.product-name, .feature .heading, .accordion .heading, .nav-regular li.level0 > a, .nav-regular .nav-submenu--mega > li > a, .mobnav-trigger, .nav-mobile li.level0 > a, .nav-mobile li.level1 > a, .nav-mobile li.level2 > a, .block .block-title, #opc-login h3, .box-account .box-head h2, .order-items h2.table-caption, .order-items h2.sub-title, .order-items .order-comments h2, .product-view .box-reviews dt .heading, .gen-tabs .tabs a, .footer .heading, #subscribe-form label, .caption, .heading {
    font-family: 'Helvetica', sans-serif; /*LM for 161933000040901*/
	font-weight: bold;
    text-transform: capitalize;
}
.content-page h2 {
    margin-bottom: 40px;
}
.content-page p {
    margin-bottom: 15px;
    line-height: 27px;
}

.main-container {
    background-color: #FFF;
    padding: 0 !important;
}

.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
    color: #3662AE; /*LM for 161933000040901*/
}

/* ================================================ */
/* Header */
/* ================================================ */

.header .userName,
.header .userSwitch,
.header .userSwitch a,
.header .welcome-msg {
    font-weight: 400;
    font-size: 1em;
}

.header .userSwitch {
    margin-left: 10px !important;
}

.header-container {
    position: relative;
}

.header-primary {
    padding: 0;
    position: relative;
}

.header-top, .header .dropdown {
    line-height: 2.1em;
}

.header-top.header.container {
    max-width: none;
} 
.header-top.header .item-left, .header-top.header .item-right {
    margin: 0;
}

header#header .top-links > .links > li > a, header#header .links-container > .links > .company > dl.company-links dt a {
    border: none;
    background-image: none;
}

header#header .top-links > .links > li > a:hover {
    color: #fff !important;
}

.user-menu {
    margin: 0;
}

#mini-cart .feature-icon-hover a {
    color: #000;
}

.links > li > a {
    padding: 0 5px;
}

.links > li > a:hover {
    background-color: #000; /*LM*/
    color: #fff;
}

.dropdown-toggle * {
    display: inline-block;
}

.module-search .form-search .button-search {
    background-color: #3662AE; /*LM for 161933000040901*/
}

.module-search .form-search .button-search .fa {
    color: #fff !important;
    font-size: 16px !important;
    margin: 0;
    padding: 14.5px 16px !important;
}
#search {
    font-size: 16px;
}
.main-header-container {
    background-color: #fff;
}
@media only screen and (min-width: 768px) and (max-width: 959px) {
    .main-header-container {
        padding-top: 10px;
    }
}
@media only screen and (max-width: 959px) {
    header#header.floating {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 999;
    }
    .search-column {
        display: none;
    }
    .xm-grid-header .grid12-4.last {
        float: right;
    }
    .fa-search::before {
        line-height:25px;
   }
}
@media only screen and (min-width:480px) and (max-width:959px) {
    .mobnav-trigger::before {
        font-size:20px;
        padding-top:5px;
        line-height:normal;
   }
}
@media only screen and (min-width:480px) {
    .mobnav-trigger::before {
        margin-right:10px;
   }
}
@media only screen and (max-width:479px) {
    .fa-shopping-cart::before {
        font-size:20px;
   }
    .nav-cart .hide-below-960 .cart-total {
        left:20px !important;
   }
    .mobnav-trigger::before {
        font-size:15px;
        line-height:25px;
   }
    #root-wrapper .nav-cart span.fa {
        padding-left:5px;
        padding-right:5px;
   }
    .form-search .button {
        padding-right:0;
   }
}
/* ================================================ */
/* Menu */
/* ================================================ */
.nav-item--home {
    display: none;
}

.nav.has-outline>.grid-full {
    margin-top: 0;
    margin-bottom: 0;
}
#nav {
    z-index: 1000;
    position: relative;
}

.nav-item.level0 {
    font-size: 16px;
}

#nav, .mobnav-trigger {
    border-bottom: none;
}

.mobnav-trigger,
.mobnav-trigger.active,
.mobnav-trigger:hover {
    background-color: transparent;
}
.mobnav-trigger::before {
    font-family:fontAwesome;
    position:relative;
    float:left;
    color:#000;
}
.nav-regular {
    background-color: transparent;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container>div[class*=grid12-].nav-column .nav-container li.nav-item--parent.parent.mega div.nav-panel--dropdown .module-sub-categories > div.nav-item > div.nav-item > ul li.page-item > ul {
    display: none;
}
#nav.nav-mobile ul.level0 .nav-item.current > a, #nav.nav-mobile ul.level0 .nav-item > a:hover, .cms-index-index .nav-regular .nav-item--home > a, .nav-mobile .nav-item.level0.current > a, .nav-mobile .nav-item.level0 > a:hover, .nav-regular .nav-item.level0.active > a, .nav-regular .nav-item.level0:hover > a {
    background-color: transparent;
}

.nav-container a, .opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu.nav-regular a, .mobnav-trigger a {
    color: rgb(0, 0, 0);
}

.nav-regular .nav-item.level0:hover > a, .nav-mobile .nav-item.level0 > a:hover {
    color: #000 !important; /*LM*/
    background-color: #fff;
}

.nav-item.active {
    background-color: #fff;
}
.nav-item.active > a,
.module-sub-categories-title.active{
    color: #3662AE !important; 
}
.nav-item.active > a .caret {
    border-top-color: #3662AE !important; 
}

.main-font, h1, h2, h3, h4, h5, h6, .section-title, .products-list .product-name, .products-grid .product-name, h3.product-name, .feature .heading, .accordion .heading, .nav-regular li.level0 > a, .nav-regular .nav-submenu--mega > li > a, .mobnav-trigger, .nav-mobile li.level0 > a, .nav-mobile li.level1 > a, .nav-mobile li.level2 > a, .block .block-title, #opc-login h3, .box-account .box-head h2, .order-items h2.table-caption, .order-items h2.sub-title, .order-items .order-comments h2, .product-view .box-reviews dt .heading, .gen-tabs .tabs a, .footer .heading, #subscribe-form label, .caption, .heading {
    font-family: 'Helvetica', sans-serif; /*LM for 161933000040901*/
	font-weight: bold;
}
h3 {
    font-size: 15px;
}
.products-grid .product-name, .products-list .product-name, h3.product-name {
    font-size: 1.4em;
    font-weight: 400;
}

.block .block-title {
    border-bottom: none;
    display: none;
}

.sorter .amount {
    color: #aaa;
}
.module-category-misc div:first-child {
    margin-left: 0px;
}

.nav-regular li.level0 > a {
    line-height: 70px;
}

.nav-regular li.level0 > a > span {
    display: inline-block;
    white-space: nowrap;
}

#nav .nav-item.level0.parent > a .caret {
    border-top-color: #fff;
}

#nav .nav-item.level0.parent:hover > a .caret {
    border-top-color: #3662AE;
}

.nav-regular .mega > .nav-panel--dropdown, .nav-regular li.level0 > .nav-panel--dropdown {
    border-top: none;
    width: 100vw !important;
}

.nav-regular li.level0 > .nav-submenu.nav-panel--dropdown {
    width: 16em !important;
}

.nav-mobile.opt-sb2 li.level0>div>div>.nav-block {
    padding: 0 10px;
}

.nav-mobile .opener:before {
    content: "\f107" !important;
    padding: 10px 10px 10px 20px !important;
}

.nav-mobile .opener.active:before, .nav-mobile li.active > .opener:before {
    content: "\f106" !important;
}

.nav-search.fly-out {
    display: none;
}

.nav-search .form-search .button-close {
    display: none;
}

.form-search .button i {
    font-style: normal;
}

.breadcrumbs .nav-filter {
    float: right;
    font-size: 16px;
    font-weight: 400;
}

.details-main-container .breadcrumbs {
    margin-bottom: 10px;
    margin-left: 10px;
    display: none;
}
#filter_list_box dt {
    font-weight: 500;
    font-size: 1.5em;
    margin-top: 5px;
    margin-bottom: 15px;
}
#filter_list_box dd .fa {
margin-right: 6px;
}
#filter_list_box dd a {
    margin-bottom: 10px;
    }

.std i {
    font-style: normal;
    font-size: 18px;
    margin-bottom: 5px;
    margin-right: 6px;
}

.category-products .toolbar {
    border: none;
    width: 100%;
    text-align: right;
}
.category-products .toolbar .sorter {
    display: inline-block;
}
.category-products-grid.hover-effect .item:hover, .products-list.hover-effect .item:hover {
    margin-left: 0;
    margin-right: 0;
}
.category-products-grid.hover-effect .item:hover {
    padding-left: 1%;
    padding-right: 1%;
}
.products-list.hover-effect .item:hover {
    padding-left: 0;
    padding-right: 0;
}
.sidebar a, .accordion-style1 li a {
    font-size: 1.3em;
}
.mini-products-list li {
    list-style: none;
}
.breadcrumbs li {
    display: inline-block;
    vertical-align: top;
}
.product-shop h1 {
    font-size: 2.5em;
    text-transform: initial;
}
.product-shop .qty-wrapper label {
    display: none;
}
.nav-filter {
    position: absolute;
    top: 0;
    right: 0;
    font-style: normal;
    font-size: 18px;
    margin-bottom: 5px;
    margin-right: 6px;
}
.nav-mobile .nav-block {
    display: initial;
}
.nav-mobile .nav-block,
.nav-mobile .nav-block > .grid12-12 {
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
}
.nav-mobile .nav-block > .grid12-12 {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.module-sub-categories .nav-item > a {
    padding: 15px 54px 15px 20px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}
.module-sub-categories .nav-item .page-item {
    padding: 0 !important;
}
.module-sub-categories .nav-item .page-item > a {
    padding: 15px 10px 15px 40px !important;
    display: block;
}
.acco .module-sub-categories div.nav-item ul li {
    padding-left: 0 !important;
}
.nav-mobile .nav-panel-inner .opener {
    position: absolute;
    top: 15px;
    right: 15px;
}
.nav-mobile li.nav-item {
    font-size: 14px;
    background-color: #f7f7f7;
}
.nav-mobile .nav-item.parent.level0 > a {
    font-weight: 600;
}
.nav-mobile .nav-item.parent.level1 > a {
    font-weight: 500;
}
.nav-mobile .nav-item.parent a {
    background-color: transparent;
    border-bottom: 1px solid #e5e5e5;
    font-size: 14px;
    color: #000;
    font-weight: 400;
}
.header-container .col-main {
    margin-bottom: 0;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}
.nav-item.show-below-960 {
    padding: 10px !important;
}
.nav-item.show-below-960 > div {
    display: block !important;
    padding: 0.3em 1em;
}
.nav-search.fly-out {
    position: fixed;
    display: block !important;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 9999;
    width: 100% !important;
    background-color: #fff;
}
.nav-search.fly-out .form-search {
    position: relative;
    padding: 10px;
}
.nav-search.fly-out .form-search .input-text {
    width: 100%;
    max-width: 100%;
    display: block;
    border: none;
    padding-right: 70px;
}
.nav-search.fly-out .form-search .button {
    color: #3662AE; /*LM for 161933000040901*/
    top: 10px;
    right: 10px;
}
.nav-search.fly-out .form-search .button-search {
    right: 45px;
}
.nav-search.fly-out #inline-search-results {
    top: 50px;
}
.nav-search.fly-out .form-search .button-close {
    display: block;
}
.block.mm-panel.mm-panel_opened {
    text-align: left;
    padding-left: 25px;
    overflow-y: scroll;
}
@media only screen and (max-width: 1024px) {
    .products-grid.centered:not(.itemslider) .item {
     width: 45%;
    }
    .itemgrid-3col .item:nth-child(3n+1) {
        clear: none;
    }
}
@media only screen and (max-width: 767px) and (min-width: 640px){
.itemgrid.itemgrid-adaptive .item:nth-child(3n+1) {
    clear: none !important;
}
}

@media only screen and (min-width: 960px) and (max-width: 1024px) {
    .sidebar-filter{
        display: none; 
    }
}
@media only screen and (max-width: 959px) {
    .sidebar .block:first-child {
        margin-top: 0;
    }
    .form-list .field,
    .form-list input.input-text,
    .form-list select,
    .progress, div[class^=strength_meter_passwd] {
        width: 100%;
    }


    .nav-item.active > a,
    .module-sub-categories-title.active{
        color: #000 !important;
        background-color: #f3f3f3 !important;
    }
    .nav-item.active > .opener {
        color: #fff !important;
    }
    .xm-grid-product .grid-left,
    .xm-grid-product .grid-right {
        width: 100% !important;
    }
    .product-shop h1,
    .product-shop .product-type-data {
        text-align: center !important;
    }
    .details-main-container .breadcrumbs {
        display: none;
    }
    .sorter .sort-by {
        display: none;
    }
    .links>li>a {
        padding: 0 10px;
    }
    .product-shop {
        margin-top: 20px;
    }
    .sidebar-category .block-content {
        -webkit-overflow-scrolling: touch;
    }
    .sidebar-category .accordion {
        overflow-x: scroll;
        width: 100%;
    }
    .sidebar-category .accordion li{
        float: none;
    }
    .nav-item .header-top-container {
        background-color: #fff;
    }
    .search-wrapper-mobile {
        width: 36px !important;
        height: 36px;
        margin: 0 !important;
        padding: 0;
        float: none;
        vertical-align: top;
    }
    .search-wrapper-mobile .form-search input {
        display: none;
    }
    .mobnav-trigger-wrapper {
        display: inline-block !important;
        vertical-align: top;
        float: none !important;
        padding: 10px .5em !important;
    }
    .mobnav-trigger {
        padding: 0;
    }
    .mobnav-trigger > span:last-child {
        display: none;
    }
    .mobnav-trigger .trigger-icon {
        padding-top: 0;
        margin: 0;
    }
    .mobnav-trigger .trigger-icon .line {
        background-color: #3662AE;
    }
    .search-wrapper-centered {
        float: none !important;
        display: inline-block !important;
    }
    #mini-cart .feature-icon-hover .hide-below-960,
    #mini-cart .feature-icon-hover .empty,
    nav .feature-icon-hover .hide-below-960,
    nav .feature-icon-hover .empty{
        display: none;
    }
    nav .feature-icon-hover {
        padding: 0 !important;
    }
    nav .feature-icon-hover .caret {
        display: none;
    }
    nav.nav {
        text-align: right;
        height: 45px;
    }
    nav.nav .header-top {
        margin: 0;
        padding: 0;
        width: 100%;
        display: block !important;
    }
    .nav-mobile {
        text-align: left;
    }
    .nav-logo, .nav-cart, .nav-search {
        display: inline-block;
        vertical-align: top;
    }
    .nav-logo {
        float: left;
        width: 200px;
        margin-top: 5px;
        margin-left: 8px;
    }
    .nav-cart {
        height: 36px;
    }
    .nav-cart .fa-shopping-cart {
        font-size: 1.5em !important;
        font-style: normal;
    }
    .nav-cart .hide-below-960 {
        visibility: hidden;
        position: relative;
        width: 0;
        height: 0;
        display: block !important;
        text-indent: -9999px;
    }
    .nav-cart .hide-below-960 .cart-total {
        visibility: visible;
        position: absolute;
        left: 25px;
        top: -35px;
        border: none;
        background-color: #3662AE; /*LM for 161933000040901*/
        border-radius: 50%;
        color: #fff;
        width: 17px;
        height: 17px;
        text-align: center;
        font-size: 9px;
        line-height: 17px;
        text-indent: 0;
    }
    .nav-cart .product-details a {
        color: #888;
    }
    .nav-cart .btn-remove,
    .nav-cart .btn-edit {
        float: right;
    }
    .nav-cart .empty, .nav-cart .caret {
        display: none;
    }
    .isPublic .nav-cart {
        display: none;
    }
    .nav-search .input-text {
        display: none;
    }
    .nav-search .button {
        height: 36px;
    }
    .cart-mobile {
        display: inline-block !important;
        vertical-align: top;
    }
    .cart-action-buttons {
        text-align: right;
    }
    .nav-item .header-top {
        display: block !important; 
    }
    .header-top .item {
        display: block;
    }
    
}
/* ================================================ */
/* Content: Logo and Search  */
/* ================================================ */
.header .logo-wrapper {
    text-align: center;
    margin: 10px 0;
}

.header .logo-wrapper a.logo {
    display: block;
}

.header .logo {
    max-width: 200px;
}

.search-wrapper-centered {
    margin-top: 18px;
    margin-bottom: 7px;
}

.search-wrapper-centered .form-search {
    margin: 0 auto;
}

.search-wrapper-centered .form-search label {
    display: none;
}


.form-search .button {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1.375em;
    color: #353535;
    padding: .5em;
}
@media only screen and (min-width: 960px) {
    .user-link-wrapper {
        float: right;
    }

    .search-wrapper .form-search .input-text, .form-search .input-text, .form-search .button {
        width: 45px;
        padding-right: 45px !important;
        background-color: transparent;
        z-index: 1;
        position: relative;
        color: rgb(56, 56, 56) !important;
        float: right;
        font-size: 18px;
    }

    .search-wrapper .form-search .input-text:hover, .search-wrapper .form-search .input-text:focus {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.2);
        color: #fff;
        transition: width 300ms ease-in-out;
        -moz-transition: width 300ms ease-in-out;
        -webkit-transition: width 300ms ease-in-out;
        -o-transition: width 300ms ease-in-out;
    }
}

@media only screen and (min-width: 768px) {
    .logo-wrapper > a {
        margin: 0 auto !important;
    }
}

/* ================================================ */
/* Content: Product Detail  */
/* ================================================ */
.product-shop .price-box .regular-price .price {
    font-size: 2em;
}

.matrix-table .price-box .regular-price .price {
    font-size: 1em;
}

.product-shop .btn-cart span span {
    padding: 5px 40px;
    font-size: 1.1666em;
}

.product-shop .z4 {
    background-color: #3662AE !important;
}

.img-box-style1 .product-image {
    border: none;
    padding: 0;
}

.product-shop h1 {
    color: #000;
}
.product-shop ul {
    list-style: disc inside;
}

.product-shop .product-options dd {
    padding-left: 0;
}

.price-box .regular-price .price, .price-box .minimal-price .price, .price-box-bundle .full-product-price .price {
    color: #3662AE; /*LM for 161933000040901*/
    font-weight: 400;
    letter-spacing: 0.5px;
    font-family: 'Helvetica', sans-serif; /*LM for 161933000040901*/
	font-weight: bold;
    font-size: 18px;
}

.product-options dt label, .product-shop .qty-wrapper label {
    font-size: 1.3em;
}

.product-shop .qty-wrapper {
    margin-top: 5px;
    margin-bottom: 15px;
    vertical-align: top;
}
.product-shop #unit-messure-wrapper {
    margin-top: 5px;
    margin-bottom: 15px;
    font-size: 15px;
    vertical-align: top;
}
.product-shop .qty-wrapper input {
    font-size: 1.2em;
    min-width: 150px;
}

.product-shop button.add-cart-button {
    width: 100%;
    margin-right: 0 !important;
    margin-left: 0;
}

.product-shop button.add-cart-button span {
    width: 100%;
    padding: 0;
    color: #fff;
    background-color: #3662AE; /*LM for 161933000040901*/
}
.product-shop button.add-cart-button span span {
    padding: 0 20px;
}
.product-shop button.add-cart-button:hover span,
.product-shop button.add-cart-button span:hover {
    background-color: #3662AE !important; /*LM for 161933000040901*/
}

.product-shop .short-description {
    font-size: 16px;
    line-height: 1.75;
    padding-top: 32px;
    padding-bottom: 24px;
}

.product-shop ul li {
    font-size: 1em;
    line-height: 1.75;
}

.product-shop p {
    font-size: 16px;
    line-height: 1.75;
}

.product-shop .product-type-data {
    text-align: right;
    font-size: 13px;
    margin-top: 11px;
}

.variations .swatches-con.list, .variations .swatchesCon.list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
}

.variations .swatches-con.list .swatch, .variations .swatchesCon.list .swatch {
    display: inline-flex !important;
}

.variations .swatches-con.list .swatch .text, .variations .swatchesCon.list .swatch .text {
    height: 46px !important;
    line-height: 46px !important;
    width: 100%;
    border-radius: 4px;
    font-size: 1.3em !important;
    overflow: hidden;
}

.variations .swatches-con.list .swatch.selected .text {
    border: 1px solid #000 !important;
    background-color: #000 !important;
    height: 46px !important;
    line-height: 46px !important;
    color: #fff !important;
    font-size: 1.3em !important;
}

.variations .swatches-con.list .swatch.available:hover .text {
    border: 1px solid #000 !important;
    height: 46px !important;
    line-height: 46px !important;
    font-size: 1.3em !important;
}

/* ================================================ */
/* Content: Others  */
/* ================================================ */
.col-main:last-of-type {
    margin-bottom: 0;
}
.fieldset {
    padding: 0;
}
.form-list input.input-text, .form-list textarea, .form-list select {
    width: 100%;
}

.form-list input[type="radio"] + label, .form-list input[type="checkbox"] + label {
    font-size: 16px;
    font-weight: 400;
    color: #737E86;
}
.full-width-container {
    width: 100vw !important;
    margin-left: calc(-50vw + 50%);
    max-width: none;
}
.full-width-container .nested-container {
    width: 100%;
    margin: 0;
}

.promo-header {
    font-family: 'Helvetica', sans-serif; /*LM for 161933000040901*/
	font-weight: bold;
    color: #fff;
    background-color: #3662AE;
    padding-top: 11px;
    font-size: 1em;
    letter-spacing: 0.5px;
}

.homepage-carousel-caption {
    margin: 0;
    position: absolute;
    bottom: 7%;
    left: 7%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: left;
    font-weight: 400;
    text-transform: none;
    line-height: 1.5em;
    letter-spacing: 0.5px;
    color: #fff;
}

.homepage-carousel-caption h2 {
    font-size: 3em;
    font-weight: 400;
    text-align: left;
    margin: 0;
    line-height: 1.5em;
}

.homepage-button {
    padding: 20px;
    font-family: 'Helvetica', sans-serif; /*LM for 161933000040901*/
	font-weight: bold;
    float: left;
    background-color: #3662AE; /*LM for 161933000040901*/
    color: #fff;
    font-weight: 400;
    letter-spacing: 0.5px;
    border: none;
    -webkit-transition: background-color 0.15s ease-out;
    -moz-transition: background-color 0.15s ease-out;
    -o-transition: background-color 0.15s ease-out;
    transition: background-color 0.15s ease-out;
}

.homepage-button:hover {
    background-color: #000;
    color: #fff;
}

.fa-chevron-right:before {
    content: "\f054";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
}

.page-content h2 {
    font-size: 40px;
    line-height: 50px;
    font-weight: bold;
    letter-spacing: -1px;
}

/*Newsletter Module Home page*/
.section-title.padding-right {
    padding-right: 0;
    font-size: 16px;
}
#subscribe-form .title{
    display: block;
}
.newsletter-label-input_email {
    display: none;
}

.newsletter-wrapper {
    width: 100%;
}

.newsletter .inputs {
    display: block;
    width: 50%;
    margin: 0 auto;
}

#subscribe-form .input-box {
    float: left;
}

#subscribe-form .input-text, #subscribe-form .button {
    font-family: 'Helvetica', sans-serif; /*LM for 161933000040901*/
	font-weight: bold;
}
html[lang="en"] .input-box.newsletter-field-input_email {
    width: 78%;
}
html[lang="fr"] .input-box.newsletter-field-input_email {
    width: 48%;
}

.input-text.required-entry.validate-input_email {
    width: 100% !important;
}

.button.btn-inline.newsletter-button {
    margin: 0;
}
html[lang="en"] .button.btn-inline.newsletter-button {
    width: 20%;
}
html[lang="fr"] .button.btn-inline.newsletter-button {
    width: 50%;
}

.newsletter button.button span {
    width: 100%;
}

.block-account .block-title {
    background: none;
}

.product-options.border-out {
    border: 1px solid red;
    padding: 10px;
}

.product-options.border-out:after {
    content: 'Please select the options';
    color: red;
    font-size: 1.2em;
}

.add-cart-fixed {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    background-color: #111;
    color: #fff;
    text-align: center;
    margin: 0 auto;
    cursor: pointer;
}

.add-cart-fixed span {
    height: 45px;
    line-height: 45px;
    font-size: 1.1666em;
}

.form-list .field {
    position: relative;
}
.home-carousel .owl-item p {
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
    position: absolute;
    top: 35%;
    text-align: center;
    width: 100%;
}
.home-carousel .owl-item .fade-on-slideshow-hover p, .home-carousel .owl-item .fade-on-slideshow-hover:hover p {
    font-size: 2.72vw;
    line-height: 3.4vw;
    font-weight: 700;
    position: absolute;
    top: 35%;
    text-align: center;
    width: 100%;
    color: #000;
}
.top-home-content {
    padding-top: 50px;
}
.top-mid-home-content {
    padding-top: 30px;
}
.mid-home-content {
    padding-top: 50px;
    padding-bottom: 35px;
}
.bottom-home-content {
    padding-bottom: 50px;
}
.top-home-content p, .top-mid-home-content p {
    font-size: 22px;
    line-height: 32px;
    text-align: center;
}
.home-logos h2 {
    margin-bottom: 55px;
}
.home-logos img {
    width: 18%;
    margin: 0 1% 40px;
    float: left;
}
.home-number {
    font-size: 90px;
    line-height: 90px;
    color: #3662AE;
}
.bottom-home-content .home-feature {
    cursor: pointer;
}
.bottom-home-content .home-feature img, .home-img-link {
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
}
.bottom-home-content .home-feature:hover img {
    opacity: 0.5;
}
.home-img-link {
    display: block;
    margin: auto;
    background-color: rgba(76,174,78,0.7);
    height: 60px;
    margin-top: -60px;
    position: relative;
    opacity: 0;
}
.bottom-home-content .home-feature:hover .home-img-link {
    opacity: 1;
}
.home-img-link .fa.fa-link {
    font-size: 25px;
    color: #fff;
    position: absolute;
    top: 17px;
}
.home-img-link:hover {
    background-color: #fff;
}
.home-img-link:hover .fa.fa-link {
    color: #3662AE;
}
@media only screen and (min-width: 960px) {
    .home-img-link {
        width: 500px;
    }
}
@media only screen and (max-width: 959px) {
    .home-img-link {
        width: 100%;
    }
}
/* ================================================ */
/* Account */
/* ================================================ */

.page-type-user .isPublic .my-account-account_menu_sign_in, .page-type-user .isPublic .my-account-account_menu_register {
    display: none;
}
/* ================================================ */
/* Category Page */
/* ================================================ */
.button.more-info {
    display: none;
}
/* ================================================ */
/* Product Page */
/* ================================================ */
.product-view .add-to-cart .qty {
    margin-left: 50px;
    height: 45px;
}
.product-type-data #stocklevel a.button span, .product-type-data #stocklevel a.button:hover span {
    background-color: #3662AE !important; /*LM for 161933000040901*/
    color: #fff !important;
}
@media only screen and (max-width: 959px) {
    .product-view .add-to-cart {
        display: grid;
    }
    .product-view .product-shop button.add-cart-button, .product-view .product-shop .qty-wrapper, .product-view .product-shop #unit-messure-wrapper {
        display: block;
    }
    .product-view  .product-shop .qty-wrapper {
        order: 1;
    }
    .product-view .add-to-cart .qty {
        margin-left: 0 !important;
        width: 100% !important;
    }
    .product-view .product-shop button.add-cart-button {
        order: 2;
    }
    .product-view .product-shop button.add-cart-button span {
        padding: 0 !important;
    }
}
/* ================================================ */
/* Contact Page */
/* ================================================ */
.leave-message {
    padding-top: 110px;
    padding-bottom: 70px;
    background-color: #110937;
    color: #fff;
}
.leave-message .page-content h2 {
    font-size: 60px;
    line-height: 70px;
    font-weight: 700;
    letter-spacing: -1px;
    text-align: center;
}
.leave-message .grid-container > div > .fieldset {
    width: 50%;
    margin: auto;
} 
.leave-message .form-list input.input-text, .leave-message textarea {
    width: 100%;
    min-height: 43px;
}
@media only screen and (min-width: 960px) {
    .leave-message .form-list li.fields.module_form_input:first-of-type, .leave-message .form-list li.fields.module_form_input:nth-of-type(2) {
        width: 49%;
        float: left;
    }
    .leave-message .form-list li.fields.module_form_input:first-of-type {
        margin-right: 1%;
    }
    .leave-message .form-list li.fields.module_form_input:nth-of-type(2) {
        margin-left: 1%;
    }
}
.leave-message button {
    margin: auto;
    float: none !important;
    display: block;
}

/* ================================================ */
/* COVID Supplies */
/* ================================================ */
.covid-form {
    margin-bottom: 30px !important;
}
.covid-form .form-list input.input-text, .covid-form .form-list select {
    width: 60%;
}
/* ================================================ */
/* Footer */
/* ================================================ */
/*Footer*/
ul.bullet li {
    background: none;
    padding-left: 0px;
    text-transform: capitalize;
    font-family: 'Helvetica', sans-serif; /*LM for 161933000040901 'Flama', sans-serif;*/
    letter-spacing: 0.5px;
}

#footer ul li a {
    text-decoration: none;
    line-height: 1.75em;
    color: #b3b3b3;
    font-size: 1em;
    font-weight: 400;
}

#footer ul li a:hover {
    border-bottom: 1px solid #b3b3b3;
}

h6.block-title.heading {
    color: #1d262b;
    font-size: 1em;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.footer-top-container img {
    opacity: 1;
    -webkit-transition: opacity 0.15s ease-out;
    -moz-transition: opacity 0.15s ease-out;
    -o-transition: opacity 0.15s ease-out;
    transition: opacity 0.15s ease-out;
}

.footer-top-container img.main-partner-logo {
    margin: 5px 0;
    opacity: 0.5;
}

.footer-top-container img.main-partner-logo:hover {
    opacity: 0.2;
}

.footer-top-container img:hover {
    opacity: 0.5;
}

.footer-bottom-container {
    background-color: transparent;
}

.footer-bottom-container .section.clearer {
    padding: 0;
}

.footer-copyright {
    font-family: 'Helvetica', sans-serif; /*LM for 161933000040901*/
}

.copyright1 {
    background: transparent;
    text-transform: capitalize;
    font-family: 'Helvetica', sans-serif; /*LM for 161933000040901*/
    letter-spacing: 0.5px;
    font-size: 1em;
    font-weight: 400;
    color: #b3b3b3;
}

.footer-container h3 {
    font-size: 22px;
    line-height: 32px;
    font-weight: 400;
    letter-spacing: 0px;
}
/*****Overriding Base.css to change the color of for certain datapoints for 161933000040901*****/
 .owl-controls .owl-next, .owl-controls .owl-prev, .owl-pagination.owl-dots button.owl-page.owl-dot.active, #scroll-to-top, #scroll-to-top:hover, .opc .active .step-title, .add-to-links span.icon-hover:hover {
     background-color:#3662AE !important;
}
 #nav .nav-panel--dropdown a:hover {
     color:#3662AE;
}