/* ============ COLORS ============ */
$white: #fff;
$black: #333;
$green: #6cc04a;
$lightGreen: #c4e86b;
$blue: #3662AE; /*LM modified it for 161933000040901 #06a7e0;*/
$lightBlue: #a6d7f2;
$teal: #3662AE; /*LM modified it for 161933000040901 #00c4b3;*/ 
$lightTeal: #91e2d5;
$grey: #e6e6e6;

/// Background and Text Color ///
@mixin backgroundTextColor($backgroundColor, $textColor) {
    background-color: $backgroundColor;
    color: $textColor;
}

/* ============ NUMBERS ============ */
$halfPercentage: 50%;

/* ============ KEEP GRID WIDTHS ============ */

@mixin keepGridWidths {
    .keep-width.grid12-1 {
        width: 6.33% !important;
    }
    .keep-width.grid12-2 {
        width: 14.66% !important;
    }
    .keep-width.grid12-3 {
        width: 23% !important;
    }
    .keep-width.grid12-4 {
        width: 31.33% !important;
    }
    .keep-width.grid12-5 {
        width: 39.67% !important;
    }
    .keep-width.grid12-6 {
        width: 48% !important;
    }
    .keep-width.grid12-7 {
        width: 56.33% !important;
    }
    .keep-width.grid12-8 {
        width: 64.67% !important;
    }
    .keep-width.grid12-9 {
        width: 73% !important;
    }
    .keep-width.grid12-10 {
        width: 81.33% !important;
    }
    .keep-width.grid12-11 {
        width: 89.67% !important;
    }
}


/* ============ DISPLAY ============ */
// None
$hide: none;

@mixin elementDisplay($displayVal) {
    display: $displayVal;
}
/* ============ SCREEN SIZES ============ */
/// Desktop ///
// min larger desktop screen width
$minLargeDesktopScreenWidth: 1280px;
// max small desktop screen width
$maxSmallDesktopScreenWidth: 1279px;
// min desktop screen width
$desktopNavChangeScreenWidth: 1125px;
// min desktop screen width
$minDesktopScreenWidth: 960px;

/// Mobile ///
// max mobile screen width
$maxMobileScreenWidth: 959px;
// min tablet screen width
$minTabletScreenWidth: 768px;
// max phablet screen width
$maxPhabletScreenWidth: 767px;
// min phablet screen width
$minPhabletScreenWidth: 480px;
// max phone screen width
$maxPhoneScreenWidth: 479px;

@mixin largeDesktopScreenForNav {
    @media only screen and (min-width: 1510px) {
        @content;
    }
}
@mixin mediumDesktopScreenForNav {
    @media only screen and (min-width: 1150px) and (max-width: 1509px) {
        @content;
    }
}

@mixin smallDesktopScreenForNav {
    @media only screen and (min-width: 960px) and (max-width: 1149px) {
        @content;
    }
}

@mixin largeDesktop {
    @media (min-width: #{$minLargeDesktopScreenWidth}) {
        @content;
    }
}
@mixin smallDesktop {
    @media (min-width: #{$minDesktopScreenWidth}) and (max-width: #{$maxSmallDesktopScreenWidth}) {
        @content;
    }
}
@mixin desktop {
    @media (min-width: #{$minDesktopScreenWidth}) {
        @content;
    }
}
@mixin desktopNavChange {
    @media (min-width: #{$minDesktopScreenWidth}) and (max-width: #{$desktopNavChangeScreenWidth}) {
        @content;
    }
}
@mixin desktopAndLargeMobile {
    @media (min-width: #{$minTabletScreenWidth}) {
        @content;
    }
}
@mixin smallDesktopAndLargeMobile {
    @media (min-width: #{$minTabletScreenWidth}) and (max-width: #{$maxSmallDesktopScreenWidth}) {
        @content;
    }
}
@mixin mobile {
    @media (max-width: #{$maxMobileScreenWidth}) {
        @content;
    }
}
@mixin tablet {
    @media (min-width: #{$minTabletScreenWidth}) and (max-width: #{$maxMobileScreenWidth}) {
        @content;
    }
}
@mixin phablet {
    @media (min-width: #{$minPhabletScreenWidth}) and (max-width: #{$maxPhabletScreenWidth}) {
        @content;
    }
}
@mixin allPhones {
    @media (max-width: #{$maxPhabletScreenWidth}) {
        @content;
    }
}
@mixin phone {
    @media (max-width: #{$maxPhoneScreenWidth}) {
        @content;
    }
}

/* ============ FONTS ============ */

/// FONT FAMILY ///
$FlamaFont: 'Flama',sans-serif;
$fontAwesome: 'fontAwesome';

//font mixin
@mixin fontFamily($family...) {
    font-family: $family;
}

//Flama font function
@mixin FlamaFont($weight: 'normal'){
    @include fontFamily($FlamaFont);
    font-weight: $weight;
}

/// Font Awesome ///
//font awesome font function
@mixin fontAwesome {
    @include fontFamily($fontAwesome);
}

//replace text with font awesome 
@mixin replaceTextWithFontAwesome($color) {
    text-indent: -9999px;
    display: inline-block;
    color: transparent;

    &:hover {
        color: transparent;
    }

    &::after {
        text-indent: 0;
        float: left;
        @include fontAwesome;
        color: $color;
    }
}

/// Sizes ///
//font sizes
$xXSmall: 8px;
$xSmall: 10px;
$small: 12px;
$medium: 14px;
$mediumLarge: 16px;
$large: 18px;
$xLarge: 20px;
$xxLarge: 22px;
$xxxLarge: 24px;
$xxxxLarge: 26px;

//line heights
$navLineHight: 137px;
$smallNavLineHeight: 110px;

/* ============ MARGINS ============ */
$regularGridMargin: 1%;

/* ============ POSITIONING ============ */
/// Relative ///
@mixin relativePosition {
    position: relative;
}
/// Absolute ///
@mixin absolutePosition {
    position: absolute;
}
//hoizontal center
@mixin horizontalAbsoluteCenter {
    @include absolutePosition();
    left: $halfPercentage;
}
@mixin horizontalAbsoluteCenterChild {
    @include relativePosition();
    left: -#{$halfPercentage};
}

@mixin center {
    margin-left: auto;
    margin-right: auto;
    float: none !important;
    display: block;
}

/* ============ BUTTONS ============ */

@mixin buttonStyling {
    background-color: $teal;
    color: $white;
    font-size: $medium;
    font-weight: bold;
}

@mixin buttonHoverStyling {
    background-color: $blue;
}

/* ============ BORDERS ============ */

@mixin regularBorder($color) {
    border: 1px solid $color;
}

/* ============ SIDEBARS ============ */

/// Sticky Sidebar ///
@mixin stickySidebarStyling {
    border-radius: $xSmall;
    box-shadow: 0 $xSmall $xSmall rgba(0,0,0,0.3);
    background-color: $lightBlue;

    @include desktop {
        &.sticky {
            position: fixed;
            top: $xSmall;
            z-index: 9999;
        }
    }
}