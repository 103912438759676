@charset "UTF-8";
/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ MARGINS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ BORDERS ============ */
/* ============ SIDEBARS ============ */
/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ MARGINS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ BORDERS ============ */
/* ============ SIDEBARS ============ */
/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ MARGINS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ BORDERS ============ */
/* ============ SIDEBARS ============ */
/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ MARGINS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ BORDERS ============ */
/* ============ SIDEBARS ============ */
/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ MARGINS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ BORDERS ============ */
/* ============ SIDEBARS ============ */
/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ MARGINS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ BORDERS ============ */
/* ============ SIDEBARS ============ */
/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ MARGINS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ BORDERS ============ */
/* ============ SIDEBARS ============ */
body #root-wrapper header#header {
  /* === DESKTOP TOP HEAADER CONTAINER === */
  /* === MOBILE HEADER === */
}
body #root-wrapper header#header .top-header-container {
  background-color: #e6e6e6;
  color: #333;
  font-family: "Helvetica", sans-serif;
  font-weight: "bold";
}
body #root-wrapper header#header .top-header-container .std .grid-container .grid-column-wrapper {
  margin: 0;
}
body #root-wrapper header#header .top-header-container .std .grid-container .grid-column-wrapper.slogan-header {
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
}
body #root-wrapper header#header .top-header-container .std .grid-container .grid-column-wrapper.slogan-header p {
  padding: 0 10px;
}
body #root-wrapper header#header .top-header-container .std .grid-container .grid-column-wrapper p {
  margin: 0;
  line-height: 30px;
}
@media (min-width: 960px) and (max-width: 1279px) {
  body #root-wrapper header#header .top-header-container .std .grid-container .grid-column-wrapper p {
    font-size: 12px;
  }
}
body #root-wrapper header#header .top-header-container .std .grid-container .grid-column-wrapper .header-top.header {
  line-height: 30px;
}
body #root-wrapper header#header .top-header-container .std .grid-container .grid-column-wrapper .header-top.header .module-header-multi .item-user-links ul.links li a {
  color: #333;
}
body #root-wrapper header#header .top-header-container .std .grid-container .grid-column-wrapper .header-top.header .module-header-multi .item-user-links ul.links li a.link-wishlist {
  display: none;
}
body #root-wrapper header#header .top-header-container .std .grid-container .grid-column-wrapper .header-top.header .module-header-multi .item-switch-language .dropdown.lang-switcher {
  line-height: 30px;
}
body #root-wrapper header#header .top-header-container .std .grid-container .grid-column-wrapper .header-top.header .module-header-multi .item-switch-language .dropdown.lang-switcher div {
  color: #333;
}
body #root-wrapper header#header .top-header-container .std .grid-container .grid-column-wrapper .header-top.header .module-header-multi .item-switch-language .dropdown.lang-switcher div.caret {
  border-top-color: #333;
}
body #root-wrapper header#header .top-header-container .std .grid-container .grid-column-wrapper .header-top.header .module-header-multi .item-switch-language .dropdown.lang-switcher.open a, body #root-wrapper header#header .top-header-container .std .grid-container .grid-column-wrapper .header-top.header .module-header-multi .item-switch-language .dropdown.lang-switcher.open div {
  color: #333;
}
body #root-wrapper header#header .top-header-container .std .grid-container .grid-column-wrapper .header-top.header .module-header-multi .item-switch-language .dropdown.lang-switcher.open a.caret, body #root-wrapper header#header .top-header-container .std .grid-container .grid-column-wrapper .header-top.header .module-header-multi .item-switch-language .dropdown.lang-switcher.open div.caret {
  border-top-color: #333;
}
body #root-wrapper header#header .top-header-container .std .grid-container .grid-column-wrapper .header-top.header .module-header-multi .item-switch-language .dropdown.lang-switcher .flag, body #root-wrapper header#header .top-header-container .std .grid-container .grid-column-wrapper .header-top.header .module-header-multi .item-switch-language .dropdown.lang-switcher .dropdown-icon {
  display: none;
}
body #root-wrapper header#header .top-header-container .std .grid-container .grid-column-wrapper .header-top.header .module-header-multi .item-welcome-msg p {
  margin: 0;
}
body #root-wrapper header#header .top-header-container .std .grid-container .grid-column-wrapper .header-top.header .module-header-multi .item-switch-customer {
  color: #333;
  margin-left: 1%;
}
body #root-wrapper header#header .top-header-container .std .grid-container .grid-column-wrapper .header-top.header .module-header-multi .item-switch-customer .userSwitch a {
  color: #333;
}
@media (min-width: 960px) and (max-width: 1279px) {
  body #root-wrapper header#header .top-header-container .std .grid-container .grid-column-wrapper .header-top.header .module-header-multi .userName, body #root-wrapper header#header .top-header-container .std .grid-container .grid-column-wrapper .header-top.header .module-header-multi .userCode, body #root-wrapper header#header .top-header-container .std .grid-container .grid-column-wrapper .header-top.header .module-header-multi .userSwitch, body #root-wrapper header#header .top-header-container .std .grid-container .grid-column-wrapper .header-top.header .module-header-multi .userSwitch a, body #root-wrapper header#header .top-header-container .std .grid-container .grid-column-wrapper .header-top.header .module-header-multi .welcome-msg, body #root-wrapper header#header .top-header-container .std .grid-container .grid-column-wrapper .header-top.header .module-header-multi #mini-cart a, body #root-wrapper header#header .top-header-container .std .grid-container .grid-column-wrapper .header-top.header .module-header-multi ul.links > li > a, body #root-wrapper header#header .top-header-container .std .grid-container .grid-column-wrapper .header-top.header .module-header-multi .lang-switcher div {
    font-size: 12px;
  }
}
body #root-wrapper header#header .top-header-container.b2b-header .item-welcome-msg {
  display: none;
}
body #root-wrapper header#header .main-header-container {
  padding: 5px 0;
  box-shadow: 0 5px 5px -5px #3662AE;
  margin-bottom: 5px;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container {
  display: flex;
  justify-content: stretch;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-] {
  width: auto;
  flex-grow: 1;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].grid12-column-clear-left {
  clear: none;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-] .logo-wrapper.header .logo {
  max-width: 220px;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].nav-column {
  flex-grow: 10;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].nav-column .nav-container ul#nav.nav-regular {
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
}
@media only screen and (min-width: 1510px) {
  body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].nav-column .nav-container ul#nav.nav-regular .nav-item.level0 {
    font-size: 26px;
  }
}
@media only screen and (min-width: 1150px) and (max-width: 1509px) {
  body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].nav-column .nav-container ul#nav.nav-regular .nav-item.level0 {
    font-size: 20px;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1149px) {
  body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].nav-column .nav-container ul#nav.nav-regular .nav-item.level0 {
    font-size: 16px;
  }
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].nav-column .nav-container ul#nav.nav-regular .nav-item.level0 > a {
  line-height: 137px;
}
@media (min-width: 960px) and (max-width: 1125px) {
  body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].nav-column .nav-container ul#nav.nav-regular .nav-item.level0 > a {
    line-height: 110px;
  }
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].nav-column .nav-container ul#nav.nav-regular .nav-item.level0 > a span.caret {
  display: none;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].nav-column .nav-container li.nav-item--parent.parent.mega div.nav-panel--dropdown .module-sub-categories > div.nav-item {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0.5%;
  padding-right: 0.5%;
  border-right: 1px solid #bbb;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].nav-column .nav-container li.nav-item--parent.parent.mega div.nav-panel--dropdown .module-sub-categories > div.nav-item:last-of-type {
  border: none;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].nav-column .nav-container li.nav-item--parent.parent.mega div.nav-panel--dropdown .module-sub-categories > div.nav-item > div.nav-item > a.module-sub-categories-title {
  background-color: #3662AE;
  color: #fff;
  font-weight: bold !important;
  padding: 0 !important;
  text-align: center;
  line-height: normal;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].nav-column .nav-container li.nav-item--parent.parent.mega div.nav-panel--dropdown .module-sub-categories > div.nav-item > div.nav-item > ul {
  margin-bottom: 10px;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].nav-column .nav-container li.nav-item--parent.parent.mega div.nav-panel--dropdown .module-sub-categories > div.nav-item > div.nav-item > ul li.page-item {
  background: none;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].nav-column .nav-container li.nav-item--parent.parent.mega div.nav-panel--dropdown .module-sub-categories > div.nav-item > div.nav-item > ul li.page-item a.module-sub-categories-title {
  padding: 0 15px !important;
  line-height: 20px;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].nav-column .nav-container li.nav-item--parent.parent.mega div.nav-panel--dropdown .module-sub-categories > div.nav-item > div.nav-item > ul li.page-item a.module-sub-categories-title span {
  font-size: 14px;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].nav-column .nav-container li.nav-item--parent.parent.mega div.nav-panel--dropdown .module-sub-categories > div.nav-item > div.nav-item > ul li.page-item a.module-sub-categories-title::before {
  content: "-";
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].nav-column .nav-container li.nav-item--parent.parent.mega div.nav-panel--dropdown .module-sub-categories > div.nav-item > div.nav-item > ul li.page-item > ul {
  display: none;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].nav-column .nav-container li.nav-item--parent.parent.mega div.nav-panel--dropdown .module-sub-categories > div.nav-item > div.nav-item.active > a.module-sub-categories-title {
  background-color: #3662AE;
  color: #fff !important;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].nav-column .nav-container li.nav-item--parent.parent .nav-panel--dropdown {
  font-family: "Helvetica", sans-serif;
  font-weight: "bold";
  font-size: 18px;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-] .module-search.search-wrapper input.input-text {
  margin-top: 46px;
}
@media (min-width: 960px) and (max-width: 1125px) {
  body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-] .module-search.search-wrapper input.input-text {
    margin-top: 32px;
  }
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-] .module-search.search-wrapper button.button.button-search {
  top: 46px;
  border-radius: 0 5px 5px 0;
}
@media (min-width: 960px) and (max-width: 1125px) {
  body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-] .module-search.search-wrapper button.button.button-search {
    top: 32px;
  }
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-] .module-search.search-wrapper #inline-search-results {
  width: 30vw;
  top: 92px;
  left: unset;
  right: 0;
}
@media (min-width: 1280px) {
  body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column {
    width: 107px;
    margin: 0;
  }
}
@media (min-width: 960px) and (max-width: 1279px) {
  body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column {
    width: 139px;
  }
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a {
  color: #3662AE;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a:hover {
  background-color: transparent;
  color: #3662AE !important;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.link-logout, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.link-login, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.link-register, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.link-admin, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.link-account {
  display: none;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.link-wishlist, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.link-favourites, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.quick-order {
  text-indent: -9999px;
  line-height: 137px;
}
@media only screen and (min-width: 1510px) {
  body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.link-wishlist, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.link-favourites, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.quick-order {
    font-size: 22px;
    width: 22px;
  }
}
@media only screen and (min-width: 1150px) and (max-width: 1509px) {
  body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.link-wishlist, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.link-favourites, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.quick-order {
    font-size: 20px;
    width: 20px;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1149px) {
  body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.link-wishlist, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.link-favourites, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.quick-order {
    font-size: 16px;
    width: 16px;
  }
}
@media (min-width: 960px) and (max-width: 1125px) {
  body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.link-wishlist, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.link-favourites, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.quick-order {
    line-height: 110px;
  }
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.link-wishlist::after, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.link-favourites::after, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.quick-order::after {
  text-indent: 0;
  float: left;
  font-family: fontAwesome;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.link-wishlist.link-wishlist::after, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.link-favourites.link-wishlist::after, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.quick-order.link-wishlist::after {
  content: "";
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.link-wishlist.link-favourites::after, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.link-favourites.link-favourites::after, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.quick-order.link-favourites::after {
  content: "";
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.link-wishlist.quick-order::after, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.link-favourites.quick-order::after, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-user-links ul.links li a.quick-order.quick-order::after {
  content: "";
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart .feature-icon-hover {
  padding: 0;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart .feature-icon-hover span, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart .feature-icon-hover div {
  color: #3662AE;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart .feature-icon-hover span.fa, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart .feature-icon-hover div.fa {
  padding: 0 5px;
  line-height: 137px;
}
@media only screen and (min-width: 1510px) {
  body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart .feature-icon-hover span.fa, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart .feature-icon-hover div.fa {
    font-size: 24px;
  }
}
@media only screen and (min-width: 1150px) and (max-width: 1509px) {
  body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart .feature-icon-hover span.fa, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart .feature-icon-hover div.fa {
    font-size: 22px;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1149px) {
  body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart .feature-icon-hover span.fa, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart .feature-icon-hover div.fa {
    font-size: 18px;
  }
}
@media (min-width: 960px) and (max-width: 1125px) {
  body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart .feature-icon-hover span.fa, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart .feature-icon-hover div.fa {
    line-height: 110px;
  }
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart .feature-icon-hover span.fa ~ div, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart .feature-icon-hover div.fa ~ div {
  display: none;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart .feature-icon-hover span.fa + .hide-below-960, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart .feature-icon-hover div.fa + .hide-below-960 {
  display: inline-block;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart .feature-icon-hover span.fa + .hide-below-960 .cart-total, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart .feature-icon-hover div.fa + .hide-below-960 .cart-total {
  position: absolute;
  right: 2px;
  text-align: center;
  border-radius: 50%;
  background-color: #3662AE;
  color: #fff;
}
@media (min-width: 1280px) {
  body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart .feature-icon-hover span.fa + .hide-below-960 .cart-total, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart .feature-icon-hover div.fa + .hide-below-960 .cart-total {
    top: 45px;
    line-height: 20px;
    height: 20px;
    width: 20px;
  }
}
@media (min-width: 960px) and (max-width: 1279px) {
  body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart .feature-icon-hover span.fa + .hide-below-960 .cart-total, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart .feature-icon-hover div.fa + .hide-below-960 .cart-total {
    top: 50px;
    line-height: 16px;
    height: 16px;
    width: 16px;
    font-size: 12px;
    font-weight: bold;
  }
}
@media (min-width: 960px) and (max-width: 1125px) {
  body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart .feature-icon-hover span.fa + .hide-below-960 .cart-total, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart .feature-icon-hover div.fa + .hide-below-960 .cart-total {
    top: 35px;
  }
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart .feature-icon-hover span.caret, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart .feature-icon-hover div.caret {
  display: none;
  border-top-color: #3662AE;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart.open span, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart.open div {
  color: #333;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart.open span.fa::before, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart.open div.fa::before {
  color: #333;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart.open span.caret, body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart.open div.caret {
  border-top-color: #333;
}
body #root-wrapper header#header .main-header-container .nested-container .grid-container > div[class*=grid12-].cart-column .module-header-multi .item-min-cart #mini-cart.open button span {
  color: #fff;
}
body #root-wrapper header#header .mobile-header-container {
  box-shadow: 0 0 5px #3662AE;
  margin-bottom: 5px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9998;
  background-color: rgba(255, 255, 255, 0.9);
}
body #root-wrapper header#header .mobile-header-container a:hover span.fa:before {
  color: #3662AE;
}
body #root-wrapper header#header .mobile-header-container .item-logo .logo-wrapper.header a.logo {
  max-width: 80px;
}
body #root-wrapper header#header .mobile-header-container .nav-search .form-search .button {
  color: #3662AE;
}
body #root-wrapper header#header .mobile-header-container .nav-cart .dropdown-toggle.opener {
  line-height: normal;
}
body #root-wrapper header#header .mobile-header-container .item-menu-icon .mh-category-btn .fa.fa-bars.userCode {
  line-height: 25px;
}
body #root-wrapper header#header .mobile-header-container .module-switch-language * {
  margin: 0 !important;
}
body #root-wrapper header#header .mobile-header-container .module-switch-language .dropdown.lang-switcher, body #root-wrapper header#header .mobile-header-container .module-switch-language .dropdown.lang-switcher:hover {
  z-index: unset;
}
body #root-wrapper header#header .mobile-header-container .module-switch-language .dropdown.lang-switcher .caret {
  display: inline-block;
  margin-left: 3px !important;
}
body #root-wrapper header#header .mobile-header-container .module-switch-language .dropdown.lang-switcher .dropdown-toggle.cover {
  line-height: 46px;
}
body #root-wrapper header#header .mobile-header-container .module-switch-language .dropdown.lang-switcher .dropdown-menu, body #root-wrapper header#header .mobile-header-container .module-switch-language .dropdown.lang-switcher:hover .dropdown-menu {
  z-index: 9999;
}
body #root-wrapper header#header .mobile-header-container .dropdown.lang-switcher.item .dropdown-menu {
  position: fixed;
  top: 46px;
}
body #root-wrapper header#header .mobile-header-container .dropdown.lang-switcher.item .dropdown-menu li {
  clear: left;
  width: 19px;
  height: 19px;
}
body #root-wrapper header#header .mobile-header-container .dropdown.lang-switcher.item .dropdown-menu li span {
  line-height: 21px;
  float: left;
  margin-right: 5px;
}
body #root-wrapper header#header .mobile-header-container .dropdown.lang-switcher.item .dropdown-menu li a {
  float: left;
  color: #333;
}
body #root-wrapper header#header .mobile-header-container .dropdown.lang-switcher.item .label.dropdown-icon {
  display: none;
}
body #root-wrapper header#header .mobile-header-container .dropdown.lang-switcher.item .label.dropdown-icon + * {
  text-indent: -9999px;
  color: transparent;
  margin-bottom: 12px !important;
  font-weight: bold;
  width: 20px;
  visibility: hidden;
}
body #root-wrapper header#header .mobile-header-container .dropdown.lang-switcher.item .label.dropdown-icon + *::after {
  text-indent: 0;
  float: left;
  color: #a6d7f2;
  visibility: visible;
}
body #root-wrapper header#header .mobile-header-container .dropdown.lang-switcher.item .label.dropdown-icon[style*="us.png"] + *::after {
  content: "EN";
}
body #root-wrapper header#header .mobile-header-container .dropdown.lang-switcher.item .label.dropdown-icon[style*="fr.png"] + *::after {
  content: "FR";
}
body #root-wrapper header#header .mobile-header-container .dropdown.lang-switcher.item .caret {
  border-top-color: #a6d7f2;
  opacity: 1;
}
body #root-wrapper header#header .mobile-header-container .dropdown.lang-switcher.item.open .label.dropdown-icon + *::after {
  color: #3662AE;
}
body #root-wrapper header#header .mobile-header-container .dropdown.lang-switcher.item.open .caret {
  border-top-color: #3662AE;
}

/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ MARGINS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ BORDERS ============ */
/* ============ SIDEBARS ============ */
body #root-wrapper .product-search-container .col-main a {
  font-weight: bold;
  font-size: 18px;
}
body #root-wrapper .product-search-container .col-main .module-sub-categories {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}
body #root-wrapper .product-search-container .col-main .module-sub-categories a {
  display: block;
  text-align: center;
}
body #root-wrapper .product-search-container .col-main .pagination li.current, body #root-wrapper .product-search-container .col-main .pagination li a:hover {
  font-weight: bold;
  background-color: #3662AE;
  color: #fff;
}
body #root-wrapper .product-search-container .col-main .category-products ul.products-grid.category-products-grid li.item {
  padding-left: 0.5%;
  padding-right: 0.5%;
  margin-right: 0.5%;
  margin-left: 0.5%;
  box-shadow: 5px 0 5px #ddd;
}
body #root-wrapper .product-search-container .col-main .category-products ul.products-grid.category-products-grid li.item:hover {
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.2);
}
body #root-wrapper .product-search-container .col-main .category-products ul.products-grid.category-products-grid li.item input.qty {
  width: 90px !important;
  height: 25px;
  margin-bottom: 5px;
  border-radius: 0;
  border: 1px solid #bbb;
}
body #root-wrapper .product-search-container a {
  color: #333;
}

/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ MARGINS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ BORDERS ============ */
/* ============ SIDEBARS ============ */
html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container {
  max-width: none;
}
html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .product-view span.fa, html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .product-view i.fa, html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .xm-grid-product span.fa, html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .xm-grid-product i.fa {
  color: #fff;
}
html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .product-view .product-shop.module-product-details .short-description, html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .xm-grid-product .product-shop.module-product-details .short-description {
  padding-top: 10px;
}
html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .product-view .product-shop.module-product-details .short-description *, html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .xm-grid-product .product-shop.module-product-details .short-description * {
  font-size: 14px;
}
html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .product-view .product-shop.module-product-details .product-type-data .price-box, html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .xm-grid-product .product-shop.module-product-details .product-type-data .price-box {
  text-align: left;
}
html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .product-view .product-shop.module-product-details .action-box ul li, html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .xm-grid-product .product-shop.module-product-details .action-box ul li {
  list-style: none;
}
html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .product-view .product-shop.module-product-details .add-cart-wrapper button.button.btn-cart.add-cart-button span span, html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .xm-grid-product .product-shop.module-product-details .add-cart-wrapper button.button.btn-cart.add-cart-button span span {
  padding: 0;
}
html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .product-view #product-tabs ul.tabs li a, html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .xm-grid-product #product-tabs ul.tabs li a {
  font-weight: normal;
  border: 1px solid #3662AE;
  border-width: 1px 1px 0 1px;
}
html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .product-view #product-tabs ul.tabs li a.current, html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .product-view #product-tabs ul.tabs li a:hover, html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .xm-grid-product #product-tabs ul.tabs li a.current, html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .xm-grid-product #product-tabs ul.tabs li a:hover {
  color: #fff;
  border-color: #3662AE;
}
html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .product-view .box-additional.box-tabs .gen-tabs #itemslider-related.owl-carousel .owl-item img, html[prefix*="product: http://ogp.me/ns/product#"] body #root-wrapper .main.container .xm-grid-product .box-additional.box-tabs .gen-tabs #itemslider-related.owl-carousel .owl-item img {
  width: auto;
}

/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ MARGINS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ BORDERS ============ */
/* ============ SIDEBARS ============ */
.page-type-cart body #root-wrapper .opc-main-container .opc .allow .step-title:hover {
  background-color: #a6d7f2;
}
.page-type-cart body #root-wrapper .opc-main-container .opc .allow .step-title h2, .page-type-cart body #root-wrapper .opc-main-container .opc .allow .step-title a {
  color: #333 !important;
}
.page-type-cart body #root-wrapper .opc-main-container .opc .allow.active .step-title h2 {
  color: #fff !important;
}
.page-type-cart body #root-wrapper .opc-main-container .opc #opc-payment ul.payment-contact li {
  list-style: disc inside;
  text-indent: 20px;
}
.page-type-cart body #root-wrapper .opc-progress-container #checkout-progress-wrapper {
  margin: 0;
}
.page-type-cart body #root-wrapper .opc-progress-container #checkout-progress-wrapper .block-progress dd.progress-data {
  border-bottom: 1px solid #777;
  padding: 8px 10px 8px 30px;
}
.page-type-cart body #root-wrapper .opc-progress-container #checkout-progress-wrapper .block-progress dd.progress-data:last-of-type {
  border: none;
}

/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ MARGINS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ BORDERS ============ */
/* ============ SIDEBARS ============ */
body #root-wrapper form#usre_rego .fieldset > .form-list > li:not(.single_field) {
  clear: left;
}
body #root-wrapper form#usre_rego .fieldset > .form-list > li:not(.single_field) .registerFormFieldContainer {
  width: 100%;
  max-width: 48%;
  margin-left: 1%;
  margin-right: 1%;
  float: left;
}
body #root-wrapper form#usre_rego .fieldset > .form-list > li.single_field {
  width: 100%;
  max-width: 48%;
  margin-left: 1%;
  margin-right: 1%;
  float: left;
}
body #root-wrapper form#usre_rego .fieldset > .form-list > li.single_field.captcha-item, body #root-wrapper form#usre_rego .fieldset > .form-list > li.single_field.control {
  clear: left;
}
body #root-wrapper form#usre_rego .fieldset > .form-list > li.single_field .registerFormFieldContainer {
  width: 100%;
}
body #root-wrapper form#usre_rego .fieldset > .form-list > div.registerFormFieldContainer {
  width: 98%;
  padding: 0 1%;
}
body #root-wrapper form#usre_rego .fieldset > .form-list > div.registerFormFieldContainer label[for=customer-type] + br + ul {
  clear: left;
}

/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ MARGINS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ BORDERS ============ */
/* ============ SIDEBARS ============ */
.page-type-cart body #root-wrapper .inline-search-results-quickorder .highlight {
  background-color: #3662AE;
}

/* ============ COLORS ============ */
/* ============ NUMBERS ============ */
/* ============ KEEP GRID WIDTHS ============ */
/* ============ DISPLAY ============ */
/* ============ SCREEN SIZES ============ */
/* ============ FONTS ============ */
/* ============ MARGINS ============ */
/* ============ POSITIONING ============ */
/* ============ BUTTONS ============ */
/* ============ BORDERS ============ */
/* ============ SIDEBARS ============ */
footer#footer.footer-container {
  background-color: #e6e6e6;
  margin-top: 50px;
}
footer#footer.footer-container .section-container .section {
  padding: 0;
}
footer#footer.footer-container .section-container .section .footer-links-column h6.block-title.heading, footer#footer.footer-container .section-container .section .social-footer-column h6.block-title.heading {
  text-transform: uppercase;
  font-size: 18px;
}
footer#footer.footer-container .section-container .section .footer-links-column div.block-content ul.bullet, footer#footer.footer-container .section-container .section .social-footer-column div.block-content ul.bullet {
  margin: 0;
}
footer#footer.footer-container .section-container .section .footer-links-column div.block-content ul.bullet li a, footer#footer.footer-container .section-container .section .social-footer-column div.block-content ul.bullet li a {
  color: #333;
}
footer#footer.footer-container .section-container .section .footer-links-column.social-footer-column h6.block-title.heading, footer#footer.footer-container .section-container .section .social-footer-column.social-footer-column h6.block-title.heading {
  font-size: 22px;
  text-transform: capitalize;
}
footer#footer.footer-container .section-container .section .footer-links-column.social-footer-column ul.bullet li, footer#footer.footer-container .section-container .section .social-footer-column.social-footer-column ul.bullet li {
  float: left;
  margin-right: 20px;
}
footer#footer.footer-container .section-container .section .footer-links-column.social-footer-column ul.bullet li a i.fa, footer#footer.footer-container .section-container .section .social-footer-column.social-footer-column ul.bullet li a i.fa {
  color: #fff;
  background-color: #333;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  font-size: 20px;
  border-radius: 50%;
}
footer#footer.footer-container .section-container .section .newsletter-wrapper {
  margin-left: 0;
  max-width: 460px;
}
footer#footer.footer-container .section-container .section .newsletter-wrapper #subscribe-form .title .text_title {
  text-transform: capitalize;
  font-size: 22px;
  font-family: "Helvetica", sans-serif;
  font-weight: "bold";
  line-height: 32px;
}
footer#footer.footer-container .section-container .section .newsletter-wrapper #subscribe-form .inputs > * {
  margin-top: 10px;
  font-size: 16px;
}
footer#footer.footer-container .section-container .section .newsletter-wrapper #subscribe-form .inputs > *.newsletter-label-input_email {
  display: none !important;
}
@media (min-width: 960px) {
  footer#footer.footer-container .section-container .section .newsletter-wrapper #subscribe-form .inputs > *.newsletter-button.button {
    float: right !important;
  }
}
@media (max-width: 959px) {
  footer#footer.footer-container .section-container .section .newsletter-wrapper #subscribe-form .inputs > *.newsletter-button.button {
    display: block;
    clear: both;
  }
}
footer#footer.footer-container .section-container .section .newsletter-wrapper #subscribe-form .inputs > *.newsletter-button.button span {
  font-size: 16px;
}
footer#footer.footer-container .section-container .section .newsletter-wrapper #subscribe-form .inputs > *.newsletter-button.button span span {
  position: relative;
}
footer#footer.footer-container .section-container .section .newsletter-wrapper #subscribe-form .inputs > * #news-letter-input_email {
  font-size: 16px;
}
footer#footer.footer-container .section-container .section .register-column {
  background-color: #3662AE;
  color: #fff;
}
footer#footer.footer-container .section-container .section .register-column .footer-b2c {
  display: inline-block;
  margin: 0 15px;
  padding: 10px;
}
footer#footer.footer-container .section-container .section .register-column .footer-b2c a.footer-button {
  border-radius: 10px;
  background-color: #3662AE;
  padding: 10px 20px;
  margin-top: 10px;
  color: #fff;
}
@media (max-width: 767px) {
  footer#footer.footer-container .section-container .section div.block-content {
    margin-bottom: 15px;
  }
  footer#footer.footer-container .section-container .section .contact-column .item-default * {
    text-align: left !important;
  }
}
@media (max-width: 767px) and (max-width: 767px) {
  footer#footer.footer-container .section-container .section .contact-column {
    margin: 8px 0 19px 0;
  }
}
@media (min-width: 960px) {
  footer#footer.footer-container .section-container {
    padding: 20px 0;
  }
}
@media (max-width: 959px) {
  footer#footer.footer-container .section-container {
    padding: 10px 0;
  }
}
@media (max-width: 767px) {
  footer#footer.footer-container .section-container .container > .container {
    max-width: none;
    width: 100%;
    padding: 0;
    margin: 0;
  }
}
@media (min-width: 768px) {
  footer#footer.footer-container .section-container.footer-top-container .section {
    display: flex;
    justify-content: space-between;
  }
  footer#footer.footer-container .section-container.footer-top-container .section div[class*=grid12-] {
    width: auto;
  }
}
footer#footer.footer-container .section-container.footer-primary-container .grid12-2 {
  width: 18%;
}
footer#footer.footer-container .footer-bottom-container {
  background-color: #e6e6e6;
  clear: left;
  padding: 0;
}
footer#footer.footer-container .footer-bottom-container .footer-bottom {
  color: #333;
  padding: 0;
}
footer#footer.footer-container .footer-bottom-container .footer-bottom .item {
  float: none;
}
footer#footer.footer-container .footer-bottom-container .footer-bottom .item .footer-copyright {
  margin: 0;
  line-height: 30px;
  text-align: center;
}

body #root-wrapper a {
  color: #3662AE; /*LM*/
}
@media (max-width: 959px) {
  body #root-wrapper .main-container .main.container {
    padding-top: 70px;
  }
}
body #root-wrapper button span, body #root-wrapper input, body #root-wrapper textarea, body #root-wrapper a.button {
  border-radius: 5px;
}
body .add-cart-fixed {
  background-color: #3662AE;
}
body .add-cart-fixed span {
  font-family: "Helvetica", sans-serif;
  font-weight: "bold";
}
body .mm-menu .mm-panel {
  background-color: #a6d7f2;
}
body .mm-menu .mm-panel .mm-navbar__title, body .mm-menu .mm-panel .mm-btn.mm-btn_prev.mm-navbar__btn {
  background-color: #3662AE;
  color: #fff;
}
body .mm-menu .mm-panel .mm-navbar__title::before, body .mm-menu .mm-panel .mm-btn.mm-btn_prev.mm-navbar__btn::before {
  border-color: #fff;
}
body .mm-menu .mm-panel .mm-listview .mm-listitem ~ .first, body .mm-menu .mm-panel .mm-listview .mm-listitem ~ .first ~ .mm-listitem {
  display: none;
}
body .modal {
  z-index: 9999 !important;
}
body .modal-backdrop {
  z-index: 9998 !important;
}

/*# sourceMappingURL=general.css.map */
