@import './variables';

html[prefix*="product: http://ogp.me/ns/product#"] {
    body {
        #root-wrapper {
            .main.container {
                max-width: none;

                .product-view, .xm-grid-product {
                    span.fa, i.fa {
                        color: $white;
                    }

                    .product-shop.module-product-details {
                        .short-description {
                            padding-top: $xSmall;

                            * {
                                font-size: $medium;
                            }
                        }

                        .product-type-data {
                            .price-box {
                                text-align: left;
                            }
                        }

                        .action-box {
                            ul {
                                li {
                                    list-style: none;
                                }
                            }
                        }

                        .add-cart-wrapper {
                            button.button.btn-cart.add-cart-button {
                                span {
                                    span {
                                        padding: 0;
                                    }
                                }
                            }
                        }
                    }

                    #product-tabs {
                        ul.tabs {
                            li {
                                a {
                                    font-weight: normal;
                                    @include regularBorder($blue);
                                    border-width: 1px 1px 0 1px;

                                    &.current, &:hover {
                                        color: $white;
                                        border-color: $teal;
                                    }
                                }
                            }
                        }
                    }

                    .box-additional.box-tabs {
                        .gen-tabs {
                            #itemslider-related.owl-carousel {
                                .owl-item {
                                    img {
                                        width: auto;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}