@import './variables';

footer#footer.footer-container {
    background-color: $grey;
    margin-top: 50px;

    .section-container {
        .section {
            padding: 0;

            .footer-links-column, .social-footer-column {
                h6.block-title.heading {
                    text-transform: uppercase;
                    font-size: $large;
                }
                div.block-content {
                    ul.bullet {
                        margin: 0;
                        
                        li {
                            a {
                                color: $black;
                            }
                        }
                    }
                }

                &.social-footer-column {
                    h6.block-title.heading {
                        font-size: $xxLarge;
                        text-transform: capitalize;
                    }

                    ul.bullet {
                        li {
                            float: left; 
                            margin-right: $xLarge;

                            a {
                                i.fa {
                                    color: $white;
                                    background-color: $black;
                                    width: 34px;
                                    height: 34px;
                                    line-height: 34px;
                                    text-align: center;
                                    font-size: $xLarge;
                                    border-radius: $halfPercentage;
                                }
                            }
                        }
                    }
                }
            }

            .newsletter-wrapper {
                margin-left: 0;
                max-width: 460px;

                #subscribe-form {
                    .title {
                        .text_title {
                            text-transform: capitalize;
                            font-size: $xxLarge;
                            @include FlamaFont('bold');
                            line-height: 32px;
                        }
                    }
                    .inputs {
                        & > * {
                            margin-top: $xSmall;
                            font-size: $mediumLarge;

                            &.newsletter-label-input_email {
                                display: none !important;
                            }

                            &.newsletter-button.button {

                                @include desktop {
                                    float: right !important;
                                }
                                
                                @include mobile {
                                    display: block;
                                    clear: both;
                                }

                                span {
                                    font-size: $mediumLarge;

                                    span {
                                        position: relative;
                                    }
                                }
                            }

                            #news-letter-input_email {
                                font-size: $mediumLarge;
                            }
                        }
                    }
                }
            }
            
            .register-column {
                background-color: $blue;
                color: $white;

                .footer-b2c {
                    display: inline-block;
                    margin: 0 15px;
                    padding: 10px;

                    a.footer-button {
                        border-radius: 10px;
                        background-color: $teal;
                        padding: 10px 20px;
                        margin-top: 10px;
                        color: $white;
                    }
                }
            }

            @include allPhones {
                div.block-content {
                    margin-bottom: 15px;
                }

                .contact-column {
                    .item-default {
                        * {
                            text-align: left !important;
                        }
                    }

                    @include allPhones {
                        margin: 8px 0 19px 0;
                    }
                }
            }
        }

        @include desktop {
            padding: 20px 0;
        }
        @include mobile {
            padding: 10px 0;
        }

        @include allPhones {
            .container > .container {
                max-width: none;
                width: 100%;
                padding: 0;
                margin: 0;
            }
        }

        &.footer-top-container {
            .section {
                @include desktopAndLargeMobile {
                    display: flex;
                    justify-content: space-between;

                    div[class*="grid12-"] {
                        width: auto;
                    }
                }
            }
        }

        &.footer-primary-container {
            .grid12-2 {
                width: 18%;
            }
        }
    }

    .footer-bottom-container {
        background-color: $grey;
        clear: left;
        padding: 0;

        .footer-bottom {
            color: $black;
            padding: 0;

            .item {
                float: none;

                .footer-copyright {
                    margin: 0;
                    line-height: 30px;
                    text-align: center;
                }
            }
        }
    }
}